.dashboardContainer {
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
}
.dashboardPanel {
  position: relative;
  width: 90%;
  margin: 1em 5%;
}
.dashboardBlock {
  background-color: #ccc;
  border: 1px dashed black;
  margin-top: 1em;
  padding-top: 1em;
}
.dashboardImage {
  width: 90%;
  margin-left: 5%;
}
.subBlock {
  width: 80%;
  margin-left: 10%;
  border: 1px solid rgb(82, 82, 82);
  margin-bottom: 0.5em;
  padding: 1em;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
}
.alert {
  color: rgb(119, 179, 95);
}
/* upload */

.uploadFormContainer {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}
.uploadForm {
  width: 80%;
  margin-left: 10%;
  padding-top: 40px;
  border: 1px dashed rgba(0, 0, 0, 0.171);
  margin-top: 20px;
  margin-bottom: 2em;
}
.uploadForm input[type="radio"] {
  width: 20px;
  height: 30px;
}

/* .uploadForm label {
  font-size: 24px;
} */
.formElement {
  width: 90%;
  margin: 1em 5%;
}
.formElement button {
  margin: 0.5em;
}
.imgToUploadThumbContainer {
  width: 100%;
  display: inline-block;
  margin: 2em 0em;
}
.imgToUploadThumb {
  width: 100px;
  margin: 0.2em;
  display: inline-block;
}
.imgToUploadThumb input {
  width: 100px;
}
.imgToUploadThumb img {
  width: 100px;
  height: 100px;
}
.selectedImage {
  border: 2px solid rgb(196, 138, 138);
}
.notSelectedImage {
  border: 2px solid #ccc;
}
.deleteImage {
  margin-top: 2em;
}

.dashboardHeading {
  font-size: 24px;
}

.selectedSiteImage img {
  border: 2px solid green;
}

.aboutImage {
  width: 300px;

  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
}
.save {
  position: relative;
  top: 0;
  right: 0;
}
.submit {
  margin: 2em 0em 1em 0em;
}
@media only screen and (max-width: 700px) {
  .back {
    position: relative;
  }
}
