.postListItem {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin: 0.5em;
  padding: 0.3em;
}
.viewPost {
  border: 2px double rgb(216, 52, 216, 0.2);
  margin: 2em;
  padding: 1em;
}
.postPicture {
  max-width: 30%;
  margin: 2em;
}

@media only screen and (max-width: 600px) {
  .postPicture {
    max-width: 90%;
    margin: auto;
  }
}
