.addPost {
  width: 100%;
  z-index: 202;

  padding-top: 1em;
  position: absolute;
  top: 10vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding: 1em;

  margin-bottom: 5em;
}
.imageBorder {
  margin: 1em;
}
