.navBarLogo {
  height: 100px;
  margin: 5px;
}
.navBarLogoCenter {
  height: 150px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.navBarContainer {
  border-bottom: 1px solid black;
}
.navBarRow {
  border: 1px solid black;
}
/* .navbar {
  min-height: 130px;
} */
.sticky-top {
  position: fixed;
  width: 100%;
  top: 0;
}
.stickySpan {
  height: 130px;
}

.nav-link {
  font-size: 22px;
}
.nav-link:hover {
  cursor: pointer;
}

.text-right {
  margin-right: 1em;
}
.navbar-toggler {
  margin-right: 1em;
}
.navbar-toggler-icon {
  z-index: 200;
}
.stickyMenu {
  position: relative;
}
.darkText {
  color: #000 !important;
}
.lightText {
  color: #fff !important;
}
.titleText {
  display: flex;
  justify-content: center;
  font-size: 40px;
  margin-left: 0.5em;
  align-items: center; /* <---- NEW    */
}
@media only screen and (max-width: 1200px) {
  .titleText {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1170px) {
  .navbar-nav {
    text-align: center;
  }
}
@media only screen and (max-width: 800px) {
  .titleText {
    font-size: 22px;
  }
}
@media only screen and (max-width: 500px) {
  .titleText {
    display: none;
  }
}
