.feature {
  border-top: 1px solid rgba(204, 204, 204, 0.486);
  margin: 1em auto;
  padding: 1em;
  text-align: left;
  width: 100%;
}
.featureEventView {
  width: 100%;
  line-height: 20px;
}
.featureContainer {
  width: 100%;
}
.small {
  line-height: 15px;
}
.featureGalleryContainer {
  width: 100%;
  min-height: 250px;
}
.landingText {
  text-align: left;
}
