.aboutImage {
  width: 80% !important;
  margin-left: 10%;
}
.aboutText {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
