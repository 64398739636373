.alert {
  color: rgb(119, 179, 95);
}
html,
body,
#app,
#root,
.appContainer,
#app > div {
  min-height: 100vh;
}
.exit {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid grey;
  position: relative;
  margin-top: 2em;
  bottom: 0;
  font-size: 10px;
}
.pageContainer {
  display: flex;
  min-height: 100vh !important;
  flex-direction: column;
  justify-content: space-between;
}
