p {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}
h1,
h2,
h3 {
  font-family: "Fjalla One", sans-serif;
  margin-bottom: 0 !important;
}
.bold {
  font-weight: 800 !important;
}
.light {
  font-weight: 200 !important;
}
a {
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}
