.moreOptions {
  color: #fff;
}
.toolbar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}
.toolbar {
  width: 100%;
  position: relative;
  height: 80px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(88, 88, 88, 0.8);
}
.toolbarLogo {
  height: 60px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}
.toolbarLogo h3 {
  margin-left: 0.5em;
  vertical-align: middle;
  color: #fff;
}
.toolbarLogo h3,
.toolbarLogo img {
  display: inline;
}
.adminLogo {
}
.moreOptionsMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #617487;
  box-shadow: -3px 5px 7px rgba(0, 0, 0, 0.5);
}
.moreOptionsMenu li {
  padding: 1rem;
}
.moreOptionsMenu {
  position: fixed;
  top: 56px;
  right: 0;
}
.moreOptions {
  cursor: pointer;
  width: 25px;
  padding: 0 10px;
}
.toolbarLinks ul li {
  padding: 1rem 1rem !important;
}
.toolbarBtn {
  padding: 0 !important;
  text-decoration: none;
  color: #fff;
}
.toolbarBtn:hover {
  color: #fcbc55;
}
.spacer {
  flex: 1;
}
.dashboardContent {
  padding-top: 80px;
  text-align: center;
  background-color: #f5f5dc;

  padding-bottom: 50px;
  width: 100%;
  height: 100%;
}
.copyright {
  position: relative;
}
.dashboardPanel {
}
.dashboardPanelHeading {
  text-align: left;
}
.dashboardControls {
  width: 100%;
  height: 100px;
}
.dashboardButton {
  cursor: pointer;
  margin: 0.3em;
}
.dashboardButton:hover {
  background-color: #999999;
}
.reportLink {
  margin: 1em;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.recentReports {
  background-color: #5f5f5f;
  padding: 10px;
}
.recentReportsText {
  padding: 10px;
  color: #fff;
}
@media only screen and (max-width: 400px) {
  .dashboardPanel {
    width: 90%;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 401px) {
  .dashboardPanel {
    width: 90%;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 701px) {
  .dashboardPanel {
    width: 80%;
    margin-left: 10%;
  }
}
@media only screen and (min-width: 1201px) {
  .dashboardPanel {
    width: 80%;
    margin-left: 10%;
  }
}
