body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fff !important;
}
.headline {
  margin: 1em auto !important;
}
.registerForm {
  margin: 2em auto;
}
.user_card {
  min-height: 320px;
  height: auto;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 100px;
  max-width: 300px;
  top: 15px;
  text-align: center;
}
.brand_logo {
  height: 100px;
  max-width: 300px;
}
.form_container {
  margin-top: 120px;
}
.login_btn {
  width: 100%;
  background: rgb(41, 41, 41) !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: rgb(41, 41, 41) !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}
.loginFormLinks {
  margin-top: 1em;
}
