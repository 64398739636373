.loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 100;
}
.loadingLogo {
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}
.complete {
  background-color: rgb(112, 223, 112);
}
.confirmation {
  position: fixed;
  z-index: 1000;
  top: 30vh;
  left: 50%;
  padding: 1em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -20%;
}
.confirmationControls button {
  margin: 0.3em;
}
.paragraphInput {
  min-width: 300px;
  width: 80%;
}
.addImage {
  position: absolute;
  z-index: 1000;
  top: 30vh;
  left: 10%;
  width: 80%;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -5%;
  background: #fff;
}
@media only screen and (max-width: 600px) {
  .confirmation {
    position: absolute;
    z-index: 1000;
    top: 30vh;
    left: 20%;
    padding: 1em;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
    margin-left: -20%;
  }
  .addImage {
    position: absolute;
    z-index: 1000;
    top: 30vh;
    left: 10%;
    width: 100%;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
    margin-left: -5%;
  }
}
