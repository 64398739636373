.popOut {
  max-width: 400px;
  width: 80%;
  z-index: 300;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1.5em;
  left: 50%;
  margin-left: -20%;
}
.colorExample {
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
}
.inline {
  width: 100%;
  display: inline-block;
}
@media only screen and (max-width: 800px) {
  .popOut {
    left: 10%;
    margin-left: 5px;
  }
}
