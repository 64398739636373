.sideDrawer {
  height: 100vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 70%;
  max-width: 400px;
  min-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sideDrawerLogo {
  margin: 0px 0px 1rem 1rem;
  width: 150px;
  height: 150px;
  margin: 2em 6em 2em 6em;
}
.sideDrawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
  text-align: left;
}
.sideDrawer a {
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
}
.sideDrawer li {
  padding: 1rem 0;
  width: 100%;
  border-top: 1px groove rgba(252, 188, 85, 0.3);
  height: 60px;
}
.firstListItem {
  border-top: 0px !important;
}
.drawerIcon {
  height: 30px;
  margin-right: 0.5rem;
}
.imageIcon {
  height: 20px;
  margin-right: 0.5rem;
}
.drawerNavLink {
  margin-left: 1rem;
}
@media only screen and (max-width: 400px) {
  .sideDrawer li {
    height: 40px;
    padding: 0.5rem 0rem;
  }
}
