.addFeature button {
  margin: 1em;
}
.addFeature {
  width: 100%;
  height: 100%;
  z-index: 200;

  padding-top: 1em;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding: 1em;

  margin-bottom: 5em;
  overflow: scroll;
}
.dynamic {
  background-color: deeppink;
}
.editFeature {
  max-width: 100%;
  min-width: 250px;
  max-height: 200px;
  z-index: 200;

  padding-top: 1em;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px;
  margin-top: -100px;

  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding: 1em;

  margin-bottom: 5em;
}
