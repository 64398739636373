.eventView {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px; /* adjust as per your needs */
  min-height: 300px; /* adjust as per your needs */
  margin-left: -200px; /* negative half of width above */
  margin-top: -150px;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  background: #ccc;
  border: 3px solid deeppink;
  z-index: 2000;
  padding: 1em;
}
